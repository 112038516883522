import React, { useMemo } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";
import FormIdentifierToModeMappingCreate, {
  ComponentAndFunctionListType,
} from "../forms/FormIdentifierToModeMappingCreate";
import { IIdentifierMapping, IdentifierMappingTypeEnum } from "../../../utilities/types/IdentifierMapping";
import { selectorGetIdentifierMappingsByIdentifierIds } from "../../../redux/identifierMapping/selectors";
import { RootState } from "../../../redux";
import { selectorGetComponentsByModelId } from "../../../redux/component/selectors";
import { selectorGetFunctionsByComponentId } from "../../../redux/function/selectors";
import { selectorGetFunctionalFailuresByFunctionId } from "../../../redux/functionalFailure/selectors";
import { selectorGetFailureModesByFunctionalFailureId } from "../../../redux/failureMode/selectors";

export interface IModalIdentifierToModeCreateMultipleProps {
  open: boolean;
  onCancelCallback(): void;
  identifierId: string;
  modelId: string;
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
}

function ModalIdentifierToModeCreateMultiple({
  onCancelCallback,
  open,
  onCompleteCallback,
  identifierId,
  modelId,
}: IModalIdentifierToModeCreateMultipleProps) {
  const store = useSelector((store: RootState) => store);
  const dispatch = useDispatch();
  const selectedIdentifiersMapping = selectorGetIdentifierMappingsByIdentifierIds(store, [identifierId]);
  const components = useMemo(() => selectorGetComponentsByModelId(store, modelId), [modelId]);
  const defaultSelectedTargetIds =
    selectedIdentifiersMapping.length > 0 ? selectedIdentifiersMapping.map((item) => item.targetId) : [];

  const dataArrayList: ComponentAndFunctionListType[] = useMemo(() => {
    let data: ComponentAndFunctionListType[] = [];
    if (components.length < 1) return [];
    components.forEach((component) => {
      data.push({
        id: component.componentId,
        name: component.name,
        type: IdentifierMappingTypeEnum.Component,
        tableData: { checked: false },
      });

      const functions = selectorGetFunctionsByComponentId(store, component.componentId);

      if (functions.length > 0) {
        functions.forEach((func) => {
          data.push({
            id: func.functionId,
            name: func.name,
            parentId: component.componentId,
            type: IdentifierMappingTypeEnum.Function,
            tableData: { checked: false },
          });

          const functionalFailures = selectorGetFunctionalFailuresByFunctionId(store, func.functionId);

          if (functionalFailures.length > 0) {
            functionalFailures.forEach((funcFailure) => {
              data.push({
                id: funcFailure.functionalFailureId,
                name: funcFailure.name,
                parentId: func.functionId,
                type: IdentifierMappingTypeEnum.FunctionalFailure,
                tableData: { checked: false },
              });

              const failureModes = selectorGetFailureModesByFunctionalFailureId(store, funcFailure.functionalFailureId);

              if (failureModes.length > 0) {
                failureModes.forEach((failureMode) => {
                  data.push({
                    id: failureMode.failureModeId,
                    name: failureMode.name,
                    parentId: funcFailure.functionalFailureId,
                    type: IdentifierMappingTypeEnum.FailureMode,
                    tableData: { checked: false },
                  });
                });
              }
            });
          }
        });
      }
    });

    return data;
  }, [components]);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link identifers to mode(s)"}
      subtitle={"Select the modes that the identifier is on."}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 800, width: "100%" }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormIdentifierToModeMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          identifierIds={[identifierId]}
          defaultSelectedTargetIds={defaultSelectedTargetIds}
          componentAndFunctionList={dataArrayList}
          modelId={modelId}
          type={IdentifierMappingTypeEnum.FailureMode}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalIdentifierToModeCreateMultiple;
