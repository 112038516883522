import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { IAssetJob } from "../../utilities/types/AssetJobs";
import { IFetchSearchAssetJobsProps, fetchSearchAssetJobs } from "../../redux/assetJob/actions";
import { useLoadNewModels } from "../model/Hooks";

interface IUseFetchAssetJobsPageHookProps extends IFetchSearchAssetJobsProps {
  minPageNumberToFetch: number;
  loadNewModals?: boolean;
  onlyFetchByIds?: boolean;
}

export const useFetchAssetJobsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
  modelId,
  status,
  assetJobId,
  text,
  onlyFetchByIds = false,
  loadNewModals = true,
}: IUseFetchAssetJobsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IAssetJob[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IAssetJob[]>([]);

  useEffect(() => {
    // This allows us to assetjob initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    if (onlyFetchByIds && !assetJobId) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        var assetJobs = (await dispatch(
          fetchSearchAssetJobs({
            pageSize,
            pageNumber,
            status,
            modelId,
            assetJobId,
            text,
          })
        )) as unknown as IAssetJob[];

        if (assetJobs && assetJobs.length) {
          setMorePages(assetJobs.length >= pageSize);
          setLastResultSet(assetJobs);
        } else {
          setLastResultSet([]);
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId, assetJobId, status, text, onlyFetchByIds]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.assetJobId === x.assetJobId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  // Load associated models for metadata like images etc
  useLoadNewModels(loadNewModals ? allResultsSet.map((x) => x.modelId) : []);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
