export interface ISummaryOutputJob {
  solverJobSummaryReportId: string;
  solverJobId: string;
  summaryOutputTypeId: string;
  status: SummaryOutputJobStatusEnum;
  progressLog: string;
  persistentErrorMessage: number;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  preSignedUrl: string;
  localPath: string;
  inputBucket: string;
  outputBucket: string;
  name: string;
  prerequisites: {
    assetJobIds: string[];
    outputJobIds: string[];
    solverJobIds: string[];
  };
}

export enum SummaryOutputJobStatusEnum {
  Pending = 10,
  InProgress = 20,
  Failed = 30,
  Complete = 40,
}

export enum SummaryOutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type TSummaryOutputJobInputFieldValue = {
  value: number;
  summaryOutputTypeInputFieldValueId?: string;
};
