export interface ITaskApplicability {
    taskApplicabilityId: string,
    modelId: string,
    taskId: string,
    failureModeId: string,
    stepDescription: string,
    acceptableLimit: string,
    subsequentEventId?: string,
    detectionProbability: number,
    pfInterval: number,
    status: number,
    orderNumber: number,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
    stepLabour: StepLabourEnum,
    duration: number,
    labourQuantity: number,
}

export enum TaskApplicabilitySearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export enum StepLabourEnum {
    Electrical = 10,
    Mechanical = 20,
    Operations = 30,
    ConditionMonitoring = 40,
    Fitter = 50,
    InstrumentTech = 60,
    CraneDriver = 80,
    Rigger = 70,
}