import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import { ITaskApplicability } from "../../../utilities/types/TaskApplicability";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";
import FormApplicabilityMappingCreate from "../forms/FormApplicabilityMappingCreate";

export interface IModalTaskApplicabilityCreateMultipleProps {
  open: boolean;
  onCancelCallback(): void;
  taskId: string;
  modelId: string;
  onCompleteCallback(taskApplicability?: ITaskApplicability[]): void;
}

function ModalTaskApplicabilityCreateMultiple({
  onCancelCallback,
  open,
  onCompleteCallback,
  taskId,
  modelId,
}: IModalTaskApplicabilityCreateMultipleProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link task to mode(s)"}
      subtitle={"Select the modes that the task can detect."}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 800 }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormApplicabilityMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          taskId={taskId}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskApplicabilityCreateMultiple;
