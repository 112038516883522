import { ISummaryOutputJob } from "./SummaryOutputJob";
import { PartialDiff } from "./misc";

export interface IOutputJob {
  solverJobTaskReportId: string;
  solverJobTaskId: string;
  outputTypeId: string;
  status: OutputJobStatusEnum;
  progressLog: string;
  persistentErrorMessage: number;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  preSignedUrl: string;
  localPath: string;
  inputBucket: string;
  outputBucket: string;
}

export enum OutputJobStatusEnum {
  Pending = 10,
  InProgress = 20,
  Failed = 30,
  Complete = 40,
}

export enum OutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type IOutputJobReport = PartialDiff<IOutputJob, ISummaryOutputJob>;
