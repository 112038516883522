import React, { lazy, useState } from "react";
import { Grid } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

import { selectorGetAssetJobById } from "../../../redux/assetJob/selectors";

import AssetJobCollapseCard from "../AssetJobCollapseCard";
import JobDetailPageWrapper from "../../generic/JobDetailPageWrapper";
import SuspenseWrapper from "../../generic/SuspenseWrapper";

const LazyPrerequisiteJobsEnquiryWidget = lazy(() => import("../../generic/widgets/PrerequisiteJobsEnquiryWidget"));
const LazySolverJobsEnquiryContainer = lazy(() => import("../../jobs/SovlerJobsEnquiryContainer"));
const LazyAssetJobTaskEnquiryContainer = lazy(() => import("../../assetJobTask/AssetJobTaskEnquiryContainer"));

interface IPageAssetJobDetailsProps {
  routeProps: RouteComponentProps<any>;
  assetJobId: string;
  loading: boolean;
}

const PageAssetJobDetails = ({ assetJobId, loading }: IPageAssetJobDetailsProps) => {
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const assetJob = useSelector((store: RootState) => selectorGetAssetJobById(store, assetJobId));

  return (
    <JobDetailPageWrapper jobLabel="Asset Jobs" jobDetailsLabel="Asset Job Details" loading={loading}>
      <AssetJobCollapseCard assetJob={assetJob} showCollapse={showCollapse} setShowCollapse={setShowCollapse}>
        <Grid container rowSpacing={4} columnSpacing={2} style={{ padding: 16 }}>
          {assetJob && (
            <Grid item xs={12} sm={6} md={4}>
              <SuspenseWrapper>
                <LazyPrerequisiteJobsEnquiryWidget prerequisites={assetJob.prerequisites} />
              </SuspenseWrapper>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4}>
            <SuspenseWrapper>
              <LazyAssetJobTaskEnquiryContainer assetJobId={assetJobId} canEdit={true} />
            </SuspenseWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SuspenseWrapper>
              <LazySolverJobsEnquiryContainer assetJobId={assetJobId} canEdit={true} />
            </SuspenseWrapper>
          </Grid>
        </Grid>
      </AssetJobCollapseCard>
    </JobDetailPageWrapper>
  );
};

export default PageAssetJobDetails;
