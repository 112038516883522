import React from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import PageJobDetails from "../components/jobs/pages/PageJobDetails";
import { useFetchSolverJobsPageHook } from "../components/jobs/Hooks";

interface RouteParams {
  jobId: string; // You can adjust the type accordingly
}

const RouteJob = () => {
  let match = useRouteMatch();
  let { jobId } = useParams<RouteParams>();

  var { fetching: fetchingJob } = useFetchSolverJobsPageHook({
    pageNumber: 1,
    pageSize: 20,
    minPageNumberToFetch: 1,
    solverJobId: jobId,
    loadNewModals: false,
  });

  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageJobDetails routeProps={props} jobId={jobId || ""} loading={fetchingJob} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteJob;
