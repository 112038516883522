import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import HeaderIcon from "@mui/icons-material/ArtTrackTwoTone";
import FormImpactMappingCreate from "../forms/FormImpactMappingCreate";
import { ITaskImpact } from "../../../utilities/types/TaskImpact";

export interface IModalTaskImpactCreateMultipleProps {
  open: boolean;
  onCancelCallback(): void;
  taskId: string;
  modelId: string;
  onCompleteCallback(taskImpact?: ITaskImpact[]): void;
}

function ModalTaskImpactCreateMultiple({
  onCancelCallback,
  open,
  onCompleteCallback,
  taskId,
  modelId,
}: IModalTaskImpactCreateMultipleProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link task to mode(s)"}
      subtitle={"Select the modes that the task corrects"}
      headerIcon={<HeaderIcon />}
      style={{ maxWidth: 800 }}
    >
      <DialogContent style={{ paddingTop: 0, paddingBottom: 16 }}>
        <FormImpactMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          taskId={taskId}
          modelId={modelId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalTaskImpactCreateMultiple;
