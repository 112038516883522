import React from "react";
import { DialogContent, DialogActions, Button } from "@mui/material";
import WidgetModalBase from "./WidgetModalBase";
import HeaderColor from "@mui/material/colors/blueGrey";
import AlertIcon from "@mui/icons-material/ReportProblem";

interface IWidgetModalConfirmationDialogProps {
  title: string;
  description: string;
  onConfirmCallback(): void;
  onCancelCallback(): void;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  subtitle: string;
}

const WidgetModalConfirmationDialog = ({
  title,
  subtitle,
  description,
  open,
  onCancelCallback,
  onConfirmCallback,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
}: IWidgetModalConfirmationDialogProps) => {
  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={title}
      subtitle={subtitle}
      headerIcon={<AlertIcon />}
      headerColor={HeaderColor[800]}
    >
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button variant="text"  onClick={onCancelCallback} style={{ flexBasis: "50%" }}>
          {cancelButtonText}
        </Button>
        <Button variant="outlined" color="primary" onClick={onConfirmCallback} style={{ flexBasis: "50%" }}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
};

export default WidgetModalConfirmationDialog;
