import React from "react";
import { Grow } from "@mui/material";

interface IAnimationWrapperProps {
  children?: React.ReactElement<any, any>;
}

function AnimationWrapper(props: React.PropsWithChildren<IAnimationWrapperProps>) {
  if (!props.children) return null;

  return (
    <Grow in={true}>
      {props.children}
    </Grow>
  );
}

export default AnimationWrapper;
