import { Button, Divider, FormControl, Grid } from "@mui/material";
import { InjectedFormikProps, withFormik } from "formik";
import React from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetFailureModesByModelId } from "../../../redux/failureMode/selectors";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import FailureModeMappingPicklist from "../../failureMode/forms/PicklistModeMapping";
import { fetchCreateTaskImpact } from "../../../redux/taskImpact/actions";
import { ITaskImpact } from "../../../utilities/types/TaskImpact";

interface FormValues {
  failureModeIds: string[];
  ageReductionFactor: number;
  orderNumber: number;
  stepDescription: string;
  stepLabour: number;
  acceptableLimit: string;
  duration: number;
  labourQuantity: number;
}

interface FormProps {
  taskId: string;
  modelId: string;
  onCompleteCallback(taskImpactMapping?: ITaskImpact[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, ITaskImpact[], AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  var failureModes = useSelector((store: RootState) => selectorGetFailureModesByModelId(store, props.modelId));

  const { setFieldValue } = props;

  const onChange = useCallback(
    (ids: IFailureMode[]) => {
      setFieldValue(
        "failureModeIds",
        ids.map((id) => id.failureModeId)
      );
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <FailureModeMappingPicklist failureModes={failureModes} onChange={onChange} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid container item xs={12}>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormImpactMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    modelId: "",
    failureModeIds: [],
    taskId: "",
    ageReductionFactor: 1,
    pfInterval: 0,
    orderNumber: 0,
    stepDescription: "",
    stepLabour: 0,
    labourQuantity: 1,
    duration: 0,
    acceptableLimit: "",
  }),
  validationSchema: Yup.object().shape({
    failureModeIds: Yup.string().label("Failure Modes").required("Please provide at least one failure mode"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, taskId } = props;

    // Map dispatch via props
    var createdTaskImpactMapping = await dispatch(
      fetchCreateTaskImpact({
        ...values,
        taskId,
      })
    );

    setSubmitting(false);

    if (createdTaskImpactMapping) onCompleteCallback(createdTaskImpactMapping);
  },
})(InnerForm);

export default FormImpactMappingCreate;
