import React from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";

import { useFetchAssetJobsPageHook } from "../components/assetJob/Hooks";
import PageAssetJobDetails from "../components/assetJob/pages/PageAssetJobDetails";

interface RouteParams {
  assetJobId: string; // You can adjust the type accordingly
}

const RouteAssetJob = () => {
  let match = useRouteMatch();
  let { assetJobId } = useParams<RouteParams>();

  var { fetching: fetchingJob } = useFetchAssetJobsPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
    assetJobId: assetJobId,
  });

  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageAssetJobDetails routeProps={props} assetJobId={assetJobId || ""} loading={fetchingJob} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteAssetJob;
