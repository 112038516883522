import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TablePagination,
  styled,
  IconButton,
  Paper,
  Button,
  Divider,
  Typography,
  MenuItem,
  ListItemIcon,
  Menu,
  ListItemText,
  InputBase,
  Badge,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useFetchFilesPageHook } from "./Hooks";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import moment from "moment";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { useDispatch } from "react-redux";
import { fetchDeleteFile } from "../../redux/file/actions";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { FileSearchOrderType, FileTypeEnum } from "../../utilities/types/File";

const DivWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
}));

const Wrapper = styled("div")(({ theme }) => ({
  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
}));

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? <CheckboxIconFilled fontSize="small" /> : <CheckboxIcon fontSize="small" />;
}

const FileTable = ({ entityType }: { entityType?: EntityTypeEnum }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [filter, setFilter] = useState<
    | undefined
    | {
        fileType?: FileTypeEnum;
        orderType?: FileSearchOrderType;
        text?: string;
      }
  >();

  const [searchText, setSearchTextFilter] = useState<string>();
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleting, setDeleting] = useState<boolean>(false);
  const { lastResultSet, fetching, morePages, setReFetch } = useFetchFilesPageHook({
    pageNumber: pageNumber + 1,
    minPageNumberToFetch: 1,
    pageSize: rowsPerPage,
    entityType,
    filter,
  });
  const dispatch = useDispatch();
  const handleChangePage = (_: any, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const [deleteFileId, setDeleteFileId] = useState<string | null>(null);

  async function handleDeleteFile() {
    setDeleting(true);
    if (deleteFileId) await dispatch(fetchDeleteFile({ fileId: deleteFileId }));
    setDeleting(false);
    setDeleteFileId(null);
    setReFetch(true);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    setFilter((prev) => ({ ...prev, text: searchText ? searchText.trim() : "" }));
    setPageNumber(0);
  }

  function updateStatusViaMenu(newStatus?: FileTypeEnum) {
    setFilter((prev) => ({ ...prev, fileType: newStatus }));
    setPageNumber(0);
  }

  function updateOrderTypeMenu(newStatus?: FileSearchOrderType) {
    setFilter((prev) => ({ ...prev, orderType: newStatus }));
    setPageNumber(0);
  }

  return (
    <Wrapper>
      <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
        <Button aria-label="menu" onClick={handleMenuClick} style={{ minWidth: 32 }}>
          <Badge color="secondary" variant="dot" invisible={!filter?.fileType && !filter?.orderType}>
            <FilterIcon />
          </Badge>
        </Button>
        <Divider className="divider" orientation="vertical" />
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(undefined)}
        >
          <div style={{ paddingRight: 16, paddingLeft: 16 }}>
            <Typography variant="overline">Filter By Type</Typography>
          </div>
          <Divider />
          <MenuItem onClick={() => updateStatusViaMenu(undefined)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.fileType === undefined} />
            </ListItemIcon>
            <ListItemText primary="None" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => updateStatusViaMenu(FileTypeEnum.Default)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.fileType === FileTypeEnum.Default} />
            </ListItemIcon>
            <ListItemText primary="Default" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => updateStatusViaMenu(FileTypeEnum.Image)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.fileType === FileTypeEnum.Image} />
            </ListItemIcon>
            <ListItemText primary="Image" />
          </MenuItem>
          <div style={{ paddingRight: 16, paddingLeft: 16 }}>
            <Typography variant="overline">Order Type</Typography>
          </div>
          <Divider />
          <MenuItem onClick={() => updateOrderTypeMenu(undefined)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.orderType === undefined} />
            </ListItemIcon>
            <ListItemText primary="None" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => updateOrderTypeMenu(FileSearchOrderType.FileId)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.orderType === FileSearchOrderType.FileId} />
            </ListItemIcon>
            <ListItemText primary="File Id" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => updateOrderTypeMenu(FileSearchOrderType.CreatedDate)}>
            <ListItemIcon>
              <StatusFilterIcon checked={filter?.orderType === FileSearchOrderType.CreatedDate} />
            </ListItemIcon>
            <ListItemText primary="Created Date" />
          </MenuItem>
        </Menu>
        <InputBase
          className="input"
          placeholder="Search Files"
          inputProps={{ "aria-label": "Search Files" }}
          onChange={onSearchTextChangeHandler}
          onSubmit={onSearchClick}
        />
        <Divider className="divider" orientation="vertical" />
        <IconButton className="iconButton" aria-label="search" onClick={onSearchClick}>
          <SearchIcon />
        </IconButton>
      </Paper>

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>File ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Bucket</TableCell>
            <TableCell>Date Uploaded</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetching ? (
            <TableRow>
              <TableCell colSpan={5}>
                <DivWrapper>
                  <CircularProgress />
                </DivWrapper>
              </TableCell>
            </TableRow>
          ) : (
            lastResultSet.map((file) => (
              <TableRow key={file.fileId}>
                <TableCell>{file.fileId}</TableCell>
                <TableCell>{file.filename}</TableCell>
                <TableCell>{file.fileBucket}</TableCell>
                <TableCell>{moment.utc(file.created).format("YYYY-MM-DD, HH:mm a")}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => setDeleteFileId(file.fileId)}
                    style={{ marginLeft: 16, border: "1px solid rgba(0,0,0,0.1)" }}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        count={morePages ? -1 : lastResultSet.length}
        component="div"
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <WidgetModalConfirmationDialog
        open={Boolean(deleteFileId)}
        title="Delete file"
        subtitle="Confirm file delete"
        description={`Are you sure that you'd like to remove this file ${deleteFileId}?`}
        onCancelCallback={() => setDeleteFileId(null)}
        onConfirmCallback={handleDeleteFile}
        confirmButtonText="Delete"
      />
      <LoaderAbsoluteCentred loading={deleting} />
    </Wrapper>
  );
};

export default FileTable;
