import React from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";

import PageOutputJobDetail from "../components/outputJob/PageOutputJobDetail";

import { useFetchSolverJobSummaryReportsPageHook } from "../components/solverJobSummaryReports/Hooks";

interface RouteParams {
  solverJobSummaryReportId: string;
}

const RouteOutputJob = () => {
  let match = useRouteMatch();
  let { solverJobSummaryReportId } = useParams<RouteParams>();

  const { fetching } = useFetchSolverJobSummaryReportsPageHook({
    pageNumber: 1,
    pageSize: 20,
    minPageNumberToFetch: 1,
    solverJobSummaryReportId,
  });

  return (
    <Switch>
      <Route
        path={`${match.path}`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageOutputJobDetail
              routeProps={props}
              solverJobSummaryReportId={solverJobSummaryReportId || ""}
              loading={fetching}
            />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteOutputJob;
