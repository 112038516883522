import { useDispatch } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import {
  Position,
  addEdge,
  useEdgesState,
  updateEdge,
  useNodesState,
  useReactFlow,
  Connection,
  Node,
  Edge,
} from "reactflow";

import { IFetchSearchWorkflowProps, fetchSearchWorkflows } from "../../redux/workflow/actions";
import { IWorkflow } from "../../utilities/types/Workflow";
import {
  NODE_TYPES,
  initialAssetDetails,
  initialBucketDetails,
  initialOutputDetails,
  initialSolverDetails,
} from "./constants";
import {
  IAssetFormValues,
  IAssetNodeDetails,
  IBucketNodeDetails,
  IOutputFormValues,
  IOutputNodeDetails,
  ISolverFormValues,
  ISolverNodeDetails,
} from "./types";

interface IUseFetchWorkflowsPageHookProps extends IFetchSearchWorkflowProps {
  minPageNumberToFetch: number;
}

export const useFetchWorkflowsPageHook = ({
  page,
  minPageNumberToFetch,
  maxPageSize,
  workflowName,
  WorfklowId,
  clientName,
}: IUseFetchWorkflowsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IWorkflow[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IWorkflow[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (page < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var workflows = (await dispatch(
          fetchSearchWorkflows({
            page,
            maxPageSize,
            workflowName,
            WorfklowId,
            clientName,
          })
        )) as unknown as IWorkflow[];

        if (workflows && workflows.length) {
          setMorePages(workflows.length >= maxPageSize);
          setLastResultSet(workflows);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, page, maxPageSize, workflowName, WorfklowId, clientName]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.workflowId === x.workflowId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};

interface IUseReactFlowHookProps {
  selectedWorkFlow?: IWorkflow;
}

interface UseReactFlowHookType {
  bucketDetails: IBucketNodeDetails;
  solverDetails: ISolverNodeDetails;
  assetDetails: IAssetNodeDetails;
  outputDetails: IOutputNodeDetails;
  nodes: Node<any>[];
  edges: Edge<any>[];
  onNodesChange: (data: any) => void;
  onEdgesChange: (data: any) => void;
  onConnect: (params: Connection) => void;
  handleShowAdd: (nodeType: NODE_TYPES) => void;
  handleCancel: (nodeType: NODE_TYPES) => void;
  handleAddUpdateBucket: (bucketPath: string, nodeType: NODE_TYPES, nodeId: string) => void;
  handleAddUpdateSolver: (newSolverDetails: ISolverFormValues, nodeType: NODE_TYPES, nodeId: string) => void;
  handleAddUpdateAsset: (assetDetails: IAssetFormValues, nodeType: NODE_TYPES, nodeId: string) => void;
  handleAddUpdateOutput: (outputDetails: IOutputFormValues, nodeType: NODE_TYPES, nodeId: string) => void;
  handleDelete: (nodeId: string, nodeType: NODE_TYPES) => void;
  handleOnEdit: (nodeData: any) => void;
  validateConnection: (connection: Connection) => boolean;
  onEdgeUpdateStart: () => void;
  onEdgeUpdate: (oldEdge: Edge<any>, newConnection: Connection) => void;
  onEdgeUpdateEnd: (data: any, edge: Edge<any>) => void;
}

export const useReactFlowHook = ({ selectedWorkFlow }: IUseReactFlowHookProps): UseReactFlowHookType => {
  const edgeUpdateSuccessful = useRef(true);
  const [bucketDetails, setBucketDetails] = useState<IBucketNodeDetails>(initialBucketDetails);
  const [solverDetails, setSolverDetails] = useState<ISolverNodeDetails>(initialSolverDetails);
  const [assetDetails, setAssetDetails] = useState<IAssetNodeDetails>(initialAssetDetails);
  const [outputDetails, setOutputDetails] = useState<IOutputNodeDetails>(initialOutputDetails);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<any>[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge<any>[]>([]);
  const { setViewport } = useReactFlow();

  const onConnect = useCallback(
    (params: Connection) => {
      const newEdge = {
        source: params.source,
        sourceHandle: params.sourceHandle,
        target: params.target,
        targetHandle: params.targetHandle,
        animated: true,
      };
      setEdges((els) => addEdge(newEdge, els));
    },
    [setEdges]
  );

  const onAdd = useCallback(
    (data: any) => {
      const newNode = {
        id: `${data.nodeType}_node-${nodes.length}`,
        data,
        position: {
          x: nodes.length * 25 + nodes.length * 5,
          y: nodes.length * 10 + 25,
        },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
        type: data.nodeType,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes, nodes]
  );

  useEffect(() => {
    if (selectedWorkFlow?.workflowObject) {
      const { workflowObject } = selectedWorkFlow;
      if (
        workflowObject.hasOwnProperty("nodes") &&
        workflowObject.hasOwnProperty("edges") &&
        workflowObject.hasOwnProperty("viewport")
      ) {
        const { nodes, edges, viewport }: any = workflowObject;
        const mapNodes = nodes.map((node: any) => ({ ...node, type: node.type || node.data.nodeType }));
        setNodes(mapNodes || []);
        setEdges(edges || []);
        setViewport(viewport);
        return;
      }
    }
    setNodes([]);
    setEdges([]);
  }, [selectedWorkFlow, setNodes, setEdges, setViewport]);

  function handleShowAdd(nodeType: NODE_TYPES) {
    if (nodeType === NODE_TYPES.BUCKET)
      setBucketDetails({
        ...initialBucketDetails,
        open: true,
      });
    else if (nodeType === NODE_TYPES.SOLVER)
      setSolverDetails({
        ...initialSolverDetails,
        open: true,
      });
    else if (nodeType === NODE_TYPES.ASSET)
      setAssetDetails({
        ...initialAssetDetails,
        open: true,
      });
    else if (nodeType === NODE_TYPES.OUTPUT)
      setOutputDetails({
        ...initialOutputDetails,
        open: true,
      });
  }

  function handleCancel(nodeType: NODE_TYPES) {
    switch (nodeType) {
      case NODE_TYPES.BUCKET:
        setBucketDetails(initialBucketDetails);
        return;

      case NODE_TYPES.SOLVER:
        setSolverDetails(initialSolverDetails);
        return;

      case NODE_TYPES.ASSET:
        setAssetDetails(initialAssetDetails);
        return;

      case NODE_TYPES.OUTPUT:
        setOutputDetails(initialOutputDetails);
        return;

      default:
        return;
    }
  }

  function handleAddUpdateBucket(bucketPath: string, nodeType: NODE_TYPES, nodeId: string) {
    const label = "Path: " + bucketPath;
    if (nodeId) {
      setNodes((prev) =>
        prev.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, label, bucketPath } } : node))
      );
    } else {
      onAdd({ label, nodeType, bucketPath });
    }
    handleCancel(nodeType);
  }

  function handleAddUpdateSolver(newSolverDetails: ISolverFormValues, nodeType: NODE_TYPES, nodeId: string) {
    const label = "Solver: " + newSolverDetails.solverName;
    if (nodeId) {
      setNodes((prev) =>
        prev.map((node) =>
          node.id === nodeId ? { ...node, data: { ...node.data, label, ...newSolverDetails } } : node
        )
      );
    } else {
      onAdd({ label, nodeType, ...newSolverDetails });
    }
    handleCancel(nodeType);
  }

  function handleAddUpdateAsset(assetDetails: IAssetFormValues, nodeType: NODE_TYPES, nodeId: string) {
    const label = "Asset: " + assetDetails.assetName;
    if (nodeId) {
      setNodes((prev) =>
        prev.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, label, ...assetDetails } } : node))
      );
    } else {
      onAdd({ label, nodeType, ...assetDetails });
    }
    handleCancel(nodeType);
  }

  function handleAddUpdateOutput(outputDetails: IOutputFormValues, nodeType: NODE_TYPES, nodeId: string) {
    const label = "Output: " + outputDetails.outputName;
    if (nodeId) {
      setNodes((prev) =>
        prev.map((node) => (node.id === nodeId ? { ...node, data: { ...node.data, label, ...outputDetails } } : node))
      );
    } else {
      onAdd({ label, nodeType, ...outputDetails });
    }
    handleCancel(nodeType);
  }

  function handleDelete(nodeId: string, nodeType: NODE_TYPES) {
    setNodes((prev) => prev.filter((node) => node.id !== nodeId));
    setEdges((prev) => prev.filter((node) => !(node.source === nodeId || node.target === nodeId)));
    handleCancel(nodeType);
  }

  function handleOnEdit(nodeData: any) {
    switch (nodeData.data.nodeType) {
      case NODE_TYPES.BUCKET:
        setBucketDetails({
          isEditMode: true,
          nodeId: nodeData.id,
          bucketPath: nodeData.data.bucketPath,
          open: true,
        });
        return;

      case NODE_TYPES.SOLVER:
        setSolverDetails({
          isEditMode: true,
          nodeId: nodeData.id,
          solverParameters: nodeData.data.solverParameters,
          solverId: nodeData.data.solverId,
          solverInputFieldValueMap: nodeData.data.solverInputFieldValueMap,
          open: true,
        });
        return;

      case NODE_TYPES.ASSET:
        setAssetDetails({
          isEditMode: true,
          nodeId: nodeData.id,
          assetFileName: nodeData.data.assetFileName,
          assetModelId: nodeData.data.assetModelId,
          open: true,
        });
        return;

      case NODE_TYPES.OUTPUT:
        setOutputDetails({
          isEditMode: true,
          nodeId: nodeData.id,
          outputParameters: nodeData.data.outputParameters,
          outputId: nodeData.data.outputId,
          summaryOutputTypeInputFieldValueMap: nodeData.data?.summaryOutputTypeInputFieldValueMap,
          open: true,
        });
        return;

      default:
        return;
    }
  }

  function validateConnection(connection: Connection) {
    const { source, target } = connection;
    if (source && target) {
      const srcBucketNode = source.startsWith(NODE_TYPES.BUCKET);
      const tarBucketNode = target.startsWith(NODE_TYPES.BUCKET);

      // Check if source is a bucket and target is asset, solver, or output
      if (
        srcBucketNode &&
        (target.startsWith(NODE_TYPES.ASSET) ||
          target.startsWith(NODE_TYPES.SOLVER) ||
          target.startsWith(NODE_TYPES.OUTPUT))
      ) {
        return true;
      }

      // Check if source is asset, solver, or output and target is a bucket
      if (
        (source.startsWith(NODE_TYPES.ASSET) ||
          source.startsWith(NODE_TYPES.SOLVER) ||
          source.startsWith(NODE_TYPES.OUTPUT)) &&
        tarBucketNode
      ) {
        return true;
      }

      // Check if both source and target are buckets
      if (srcBucketNode && tarBucketNode) {
        return true;
      }
    }
    // If none of the conditions are met, return false
    return false;
  }

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: any, newConnection: any) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: any) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  return {
    bucketDetails,
    solverDetails,
    assetDetails,
    outputDetails,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    handleShowAdd,
    handleCancel,
    handleAddUpdateBucket,
    handleAddUpdateSolver,
    handleAddUpdateAsset,
    handleAddUpdateOutput,
    handleDelete,
    handleOnEdit,
    validateConnection,
    onEdgeUpdateStart,
    onEdgeUpdate,
    onEdgeUpdateEnd,
  };
};
