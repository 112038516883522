import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ISummaryOutputJob } from "../../utilities/types/SummaryOutputJob";
import { IFetchSearchSummaryOutputJobProps, fetchSearchSummaryOutputJob } from "../../redux/summaryOutputJob/actions";

interface IUseFetchSolverJobSummaryReportsPageHookProps extends IFetchSearchSummaryOutputJobProps {
  minPageNumberToFetch: number;
  onlyFetchByIds?: boolean;
}

export const useFetchSolverJobSummaryReportsPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  text,
  pageSize,
  outputTypeId,
  solverJobSummaryReportId,
  solverJobId,
  status,
  onlyFetchByIds = false,
}: IUseFetchSolverJobSummaryReportsPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<ISummaryOutputJob[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<ISummaryOutputJob[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if (pageNumber < minPageNumberToFetch) {
      return;
    }

    if (onlyFetchByIds && !solverJobSummaryReportId) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        var solverJobSummaryReports = (await dispatch(
          fetchSearchSummaryOutputJob({
            pageSize,
            pageNumber,
            outputTypeId,
            solverJobSummaryReportId,
            solverJobId,
            status,
            text,
          })
        )) as unknown as ISummaryOutputJob[];

        if (solverJobSummaryReports && solverJobSummaryReports.length) {
          setMorePages(solverJobSummaryReports.length >= pageSize);
          setLastResultSet(solverJobSummaryReports);
        } else {
          setLastResultSet([]);
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [
    minPageNumberToFetch,
    dispatch,
    pageNumber,
    pageSize,
    outputTypeId,
    solverJobSummaryReportId,
    status,
    text,
    solverJobId,
    onlyFetchByIds,
  ]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (
      lastResultSet.some((x) => !allResultsSet.some((y) => y.solverJobSummaryReportId === x.solverJobSummaryReportId))
    ) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
