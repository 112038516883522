import { Button, Divider, Grid, Paper, styled } from "@mui/material";
import { FormikProps, withFormik } from "formik";
import teal from "@mui/material/colors/deepPurple";
import IconColor from "@mui/material/colors/orange";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import MaterialTable from "@material-table/core";
import * as Yup from "yup";
import { RootState } from "../../../redux";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";

import { IIdentifierMapping, IdentifierMappingTypeEnum } from "../../../utilities/types/IdentifierMapping";
import { fetchCreateIdentifierMapping } from "../../../redux/identifierMapping/actions";
import { PaperProps } from "@mui/material/Paper";
import FunctionIcon from "@mui/icons-material/FunctionsOutlined";
import BuildIcon from "@mui/icons-material/SettingsTwoTone";
import FunctionalFailureIcon from "@mui/icons-material/SmsFailedRounded";
import FailureModeIcon from "@mui/icons-material/DeveloperModeRounded";

interface FormValues {
  targetIds: string[];
}

export interface ComponentAndFunctionListType {
  id: string;
  name: string;
  type: IdentifierMappingTypeEnum;
  parentId?: string;
  tableData: { checked: boolean };
}

interface FormProps {
  identifierIds: string[];
  defaultSelectedTargetIds: string[];
  modelId: string;
  type: IdentifierMappingTypeEnum;
  componentAndFunctionList: ComponentAndFunctionListType[];
  onCompleteCallback(identifierMapping?: IIdentifierMapping[]): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IIdentifierMapping[], AnyAction>;
}

const FormWrapper = styled("form")(({ theme }) => ({
  width: "100%",
  "& .iconWrapper": {
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .faliureModeIcon": {
    backgroundColor: IconColor[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .funtionFailureIcon": {
    backgroundColor: IconColor[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
  "& .funtionIcon": {
    backgroundColor: teal[500],
    color: "#FFF",
    padding: theme.spacing(1),
    paddingBottom: 0,
    borderRadius: 40,
    height: 40,
    width: 40,
    textAlign: "center",
  },
}));

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
  const { componentAndFunctionList, setFieldValue } = props;

  const tableData: any = useMemo(() => {
    let data: any = [...componentAndFunctionList];

    data.forEach((item: any) => {
      item.tableData.checked = props.values.targetIds.includes(item.id);
    });

    return data;
  }, [componentAndFunctionList, props.values.targetIds]);

  const onSelectionChange = useCallback(
    (rowList: any[]) => {
      const checkedTargetIds = rowList.filter((item: any) => item?.tableData?.checked === true).map((item) => item.id);
      const uniqueArray = Array.from(new Set(checkedTargetIds));
      setFieldValue("targetIds", uniqueArray);
    },
    [setFieldValue]
  );

  return (
    <FormWrapper onSubmit={props.handleSubmit}>
      <MaterialTable
        title=""
        components={{
          Container: (props: React.JSX.IntrinsicAttributes & PaperProps) => <Paper {...props} elevation={0} />,
        }}
        data={tableData}
        columns={[
          {
            title: "Type",
            field: "type",
            render: (rowData) => {
              return (
                <>
                  {rowData.type === IdentifierMappingTypeEnum.Component && (
                    <div className="iconWrapper">
                      <BuildIcon />
                    </div>
                  )}
                  {rowData.type === IdentifierMappingTypeEnum.Function && (
                    <div className="funtionIcon">
                      <FunctionIcon />
                    </div>
                  )}

                  {rowData.type === IdentifierMappingTypeEnum.FunctionalFailure && (
                    <div className="funtionFailureIcon">
                      <FunctionalFailureIcon />
                    </div>
                  )}
                  {rowData.type === IdentifierMappingTypeEnum.FailureMode && (
                    <div className="faliureModeIcon">
                      <FailureModeIcon />
                    </div>
                  )}
                </>
              );
            },
          },
          { title: "C/F/FF/FM", field: "name" },
        ]}
        parentChildData={(row: { parentId: any }, rows: any[]) => rows.find((a) => a.id === row.parentId)}
        options={{
          selection: true,
          search: false,
          toolbar: false,
          pageSize: 10,
        }}
        onSelectionChange={onSelectionChange}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid container item xs={12}>
          <div style={{ flex: "1" }} />
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Add
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

const FormApplicabilityMappingCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    modelId: "",
    targetIds: props.defaultSelectedTargetIds,
    identifierIds: [],
    type: IdentifierMappingTypeEnum.FailureMode,
  }),
  validationSchema: Yup.object().shape({
    targetIds: Yup.string().label("Failure Modes").required("Please provide at least one target"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, modelId, type, componentAndFunctionList } = props;

    const identifierMappingsToCreate = values.targetIds.flatMap((target) => {
      const findRecords = componentAndFunctionList.find((item) => item.id === target);
      return props.identifierIds.map((identifier) => ({
        modelId, // Assuming modelId is obtained from some variable
        targetId: target,
        identifierId: identifier,
        mappingType: findRecords?.type ?? type,
      }));
    });

    // Map dispatch via props
    var createdIdentifierMappings = await dispatch(
      fetchCreateIdentifierMapping({
        identifierMappings: identifierMappingsToCreate,
      })
    );

    setSubmitting(false);

    if (createdIdentifierMappings) onCompleteCallback(createdIdentifierMappings);
  },
})(InnerForm);

export default FormApplicabilityMappingCreate;
