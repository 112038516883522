import { AppThunk } from "..";
import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { ISummaryOutputJob, SummaryOutputJobStatusEnum } from "../../utilities/types/SummaryOutputJob";
import { TSummaryOutputTypeInputFieldValue } from "../../utilities/types/SummaryOutputType";
import { ACTIONS_SUMMARY_OUTPUT_JOB, SummaryOutputJobSearchOrderTypeEnum } from "./types";

export const receiveSummaryOutputJob = (summaryOutputJobs: ISummaryOutputJob[]) => {
  var byIdObjectToDispatch: { [key: string]: ISummaryOutputJob } = {};

  for (var i = 0; i < summaryOutputJobs.length; i++) {
    byIdObjectToDispatch[summaryOutputJobs[i].solverJobSummaryReportId] = summaryOutputJobs[i];
  }

  return {
    type: ACTIONS_SUMMARY_OUTPUT_JOB.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteSummaryOutputJob = (summaryOutputJob: ISummaryOutputJob) => ({
  type: ACTIONS_SUMMARY_OUTPUT_JOB.DELETE,
  byId: { [summaryOutputJob.solverJobSummaryReportId]: summaryOutputJob },
});

interface IPrerequisites {
  assetJobIds?: string[];
  outputJobIds?: string[];
  solverJobIds?: string[];
}

export interface IFetchCreateSummaryOutputJobProps {
  solverJobId: string;
  summaryOutputTypeInputs: { [id: string]: ISummaryOutputTypeInput };
  inputBucket?: string;
  outputBucket?: string;
  prerequisites?: IPrerequisites;
}

export interface ISummaryOutputTypeInput {
  summaryOutputTypeId: string;
  displayName: string;
  inputFieldValueMap: { [id: string]: TSummaryOutputTypeInputFieldValue };
}

export const fetchCreateSummaryOutputJob =
  (request: IFetchCreateSummaryOutputJobProps): AppThunk<Promise<ISummaryOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/SolverJobSummaryReports`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(request),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobSummaryReports) {
        dispatch(receiveSummaryOutputJob(parsedResp.data.solverJobSummaryReports));
        return parsedResp.data.solverJobSummaryReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating reports.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating reports.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateSummaryOutputJobProps {
  solverJobSummaryReportId: string;
  name: string;
  description: string;
  orderNumber: number;
}

export const fetchUpdateSummaryOutputJob =
  (props: IFetchUpdateSummaryOutputJobProps): AppThunk<Promise<ISummaryOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverJobSummaryReports`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobSummaryReports) {
        dispatch(receiveSummaryOutputJob(parsedResp.data.solverJobSummaryReports));
        return parsedResp.data.solverJobSummaryReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating report.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating report.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchSummaryOutputJobProps {
  pageNumber: number;
  pageSize: number;
  solverJobSummaryReportId?: string;
  solverJobId?: string;
  outputTypeId?: string;
  text?: string;
  status?: SummaryOutputJobStatusEnum;
  createdBy?: string;
  orderType?: SummaryOutputJobSearchOrderTypeEnum;
}

export const fetchSearchSummaryOutputJob =
  (searchParams: IFetchSearchSummaryOutputJobProps): AppThunk<Promise<ISummaryOutputJob[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(
        AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobSummaryReports`, searchParams),
        {
          method: "GET",
          headers: headers,
        }
      );

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobSummaryReports) {
        dispatch(receiveSummaryOutputJob(parsedResp.data.solverJobSummaryReports));
        return parsedResp.data.solverJobSummaryReports;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching reports.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching reports.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteSummaryOutputJobProps {
  solverJobSummaryReportId: string;
}

export const fetchDeleteSummaryOutputJob =
  (props: IFetchDeleteSummaryOutputJobProps): AppThunk<Promise<ISummaryOutputJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(
        AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobSummaryReports`, props),
        {
          method: "DELETE",
          headers: headers,
        }
      );

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.solverJobSummaryReports &&
        parsedResp.data.solverJobSummaryReports.length
      ) {
        dispatch(requestDeleteSummaryOutputJob(parsedResp.data.solverJobSummaryReports[0]));
        return parsedResp.data.solverJobSummaryReports[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting report.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting report.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
