import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Collapse, Chip, Card as MuiCard, Typography, styled, IconButton, Tooltip, Popover, Box } from "@mui/material";

import { useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PersonIcon from "@mui/icons-material/PersonSharp";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadIcon from "@mui/icons-material/CloudDownloadOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import RedColors from "@mui/material/colors/red";

import { RootState } from "../../redux";

import { GetUserLinkByUserId } from "../../routes/RouteLinkHelpers";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { useFileSourceSingleHook } from "../file/Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import { ImageWithFallback } from "../generic/ImageWithFallback";
import ModalJobProgressLog from "../jobs/modals/ModalJobProgressLog";
import { ISummaryOutputJob, SummaryOutputJobStatusEnum } from "../../utilities/types/SummaryOutputJob";
import { selectorGetSummaryOutputTypeById } from "../../redux/summaryOutputType/selectors";

const getStatusColor = (status: SummaryOutputJobStatusEnum) => {
  switch (status) {
    case SummaryOutputJobStatusEnum.InProgress:
      return "info";

    case SummaryOutputJobStatusEnum.Complete:
      return "success";

    case SummaryOutputJobStatusEnum.Failed:
      return "error";

    default:
      return "default";
  }
};

interface OutputJobCollapseCardProps {
  solverJobSummaryReport?: ISummaryOutputJob;
  showCollapse: boolean;
  setShowCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const Card = styled(MuiCard)(({ theme }) => ({
  overflow: "unset",
  marginTop: `${theme.spacing(4)} !important`,
  ".inner-wrapper": {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: theme.spacing(2),
    flexWrap: "wrap",
    ".iconContainer": {
      position: "absolute",
      left: 0,
      top: "-28px",
      zIndex: 11,
      display: "grid",
      placeContent: "center",
      height: "75px",
      width: "75px",
      backgroundColor: "#37474f",
      borderRadius: "4px",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      svg: {
        color: "#FFF",
        width: "1.3em",
        height: "1.3em",
      },
    },
    ".icon-btn": {
      backgroundColor: "#FFF",
      border: "1px solid #EEE",
    },
    ".content-wrapper": {
      cursor: "pointer",
      userSelect: "none",
      display: "flex",
      alignItems: "center",
      gap: 16,
      flex: 1,
      flexShrink: 0,
      position: "relative",
      paddingLeft: theme.spacing(11),
    },
    ".right-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
  },
  ".card-img": {
    width: 75,
    height: 75,
    borderRadius: 3,
    objectFit: "cover",
  },
  ".collapse-wrapper": {},
}));

const OutputJobCollapseCard: React.FC<OutputJobCollapseCardProps> = ({
  solverJobSummaryReport,
  showCollapse,
  setShowCollapse,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isAdmin = useSelector((store: RootState) => selectorGetUserPermissionIsAdmin(store));
  const [logModalVisible, setLogModalVisible] = useState<boolean>(false);
  const toggleCollapse = () => setShowCollapse((prev) => !prev);

  const outputTypeSummary = useSelector((store: RootState) =>
    selectorGetSummaryOutputTypeById(store, solverJobSummaryReport?.summaryOutputTypeId ?? "")
  );

  const solverimageUrl = useFileSourceSingleHook({
    fileId: outputTypeSummary?.mainImageId ?? "",
    fileType: FileTypeEnum.Image,
  });

  if (!solverJobSummaryReport) return null;

  return (
    <Card>
      <div className="inner-wrapper">
        <div className="content-wrapper" onClick={toggleCollapse}>
          <div className="iconContainer">
            <ImageWithFallback className="card-img" src={solverimageUrl ? solverimageUrl : ""} />
          </div>
          <div style={{ flex: 1 }}>
            <Typography fontWeight={600}>{solverJobSummaryReport?.name || "Output Job Details"}</Typography>

            <Typography component="span" variant="body2" className="inline" color="textPrimary">
              {moment.utc(solverJobSummaryReport.created || solverJobSummaryReport.created).format("MM:HHA DD/MM/YYYY")}{" "}
            </Typography>
          </div>
        </div>

        <div className="right-wrapper">
          <Chip
            color={getStatusColor(solverJobSummaryReport.status)}
            label={SummaryOutputJobStatusEnum[solverJobSummaryReport.status]}
          />
          {solverJobSummaryReport.persistentErrorMessage && (
            <>
              <Tooltip title="Error Message" placement="top" arrow>
                <IconButton size="small" style={{ border: "1px solid rgba(0,0,0,0.1)" }} onClick={handleClick}>
                  <ErrorIcon style={{ fill: RedColors[600] }} />
                </IconButton>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box p={2} maxWidth={320}>
                  <Typography variant="body2" mb={1}>
                    Error Message
                  </Typography>
                  <Typography variant="body2" color={RedColors[600]}>
                    {solverJobSummaryReport.persistentErrorMessage}
                  </Typography>
                </Box>
              </Popover>
            </>
          )}

          {solverJobSummaryReport.status === SummaryOutputJobStatusEnum.Complete && (
            <a href={solverJobSummaryReport.preSignedUrl} target="_blank" rel="noopener noreferrer">
              <IconButton size="small" style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                <DownloadIcon />
              </IconButton>
            </a>
          )}

          <Tooltip title="Progress Log" placement="top" arrow>
            <IconButton
              size="small"
              style={{ border: "1px solid rgba(0,0,0,0.1)" }}
              onClick={() => setLogModalVisible(true)}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>

          {isAdmin ? (
            <Tooltip title="Created By User" placement="top" arrow>
              <IconButton
                size="small"
                style={{ border: "1px solid rgba(0,0,0,0.1)" }}
                component={NavLink}
                target="_blank"
                to={GetUserLinkByUserId(solverJobSummaryReport.createdBy)}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : null}
          <IconButton aria-label="collapse" aria-controls="collapse-menu" aria-haspopup="true" onClick={toggleCollapse}>
            {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div>
        <Collapse className={showCollapse ? "collapse-wrapper" : ""} in={showCollapse}>
          {children}
        </Collapse>
      </div>
      <ModalJobProgressLog
        open={logModalVisible}
        progressLog={solverJobSummaryReport.progressLog}
        onCancelCallback={() => setLogModalVisible(false)}
      />
    </Card>
  );
};

export default OutputJobCollapseCard;
