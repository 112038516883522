import * as Yup from "yup";

export const workflowValidationSchema = Yup.object().shape({
  clientName: Yup.string()
    .label("Label")
    .min(3, "Please input 3 characters or more")
    .max(50, "Please input 50 characters or less")
    .required("Please provide a client name"),
  workflowName: Yup.string()
    .label("Label")
    .min(3, "Please input 3 characters or more")
    .max(50, "Please input 50 characters or less")
    .required("Please provide a workflow name"),
  clientBaseBucket: Yup.string()
    .label("Label")
    .min(3, "Please input 3 characters or more")
    .max(50, "Please input 50 characters or less")
    .required("Please provide an client bucket"),
});

export const bucketValidationSchema = Yup.object().shape({
  bucketPath: Yup.string()
    .label("Label")
    .min(3, "Please input 3 characters or more")
    .max(50, "Please input 50 characters or less")
    .required("Please provide a path"),
});

export const solverValidationSchema = Yup.object().shape({
  solverParameters: Yup.string()
    .label("Label")
    .min(2, "Please input 2 characters or more")
    .max(5000, "Please input 5000 characters or less")
    .required("Please provide a parameters"),
  solverId: Yup.string().required("Please provide a solver"),
});

export const assetValidationSchema = Yup.object().shape({
  assetFileName: Yup.string()
    .label("Label")
    .min(3, "Please input 3 characters or more")
    .max(50, "Please input 50 characters or less")
    .required("Please provide a file name."),
  assetModelId: Yup.string().required("Please provide a asset model"),
});

export const outputValidationSchema = Yup.object().shape({
  outputParameters: Yup.string()
    .label("Label")
    .min(2, "Please input 2 characters or more")
    .max(5000, "Please input 5000 characters or less")
    .required("Please provide a parameters"),
  outputId: Yup.string().required("Please provide a output"),
});

export enum NODE_TYPES {
  BUCKET = "bucket",
  ASSET = "asset",
  SOLVER = "solver",
  OUTPUT = "output",
}

export const initialBucketDetails = {
  isEditMode: false,
  nodeId: "",
  bucketPath: "",
  open: false,
};

export const initialSolverDetails = {
  isEditMode: false,
  nodeId: "",
  solverParameters: "",
  solverId: "",
  open: false,
  solverInputFieldValueMap: null,
};

export const initialAssetDetails = {
  isEditMode: false,
  nodeId: "",
  assetFileName: "",
  assetModelId: "",
  open: false,
};

export const initialOutputDetails = {
  isEditMode: false,
  nodeId: "",
  outputParameters: "",
  outputId: "",
  open: false,
  summaryOutputTypeInputFieldValueMap: null,
};
