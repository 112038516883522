import { ListItemButton, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { IFailureMode } from "../../../utilities/types/FailureMode";
import { useSelector } from "react-redux";
import { selectorGetComponentByFunctionalFailureId } from "../../../redux/component/selectors";
import { RootState } from "../../../redux";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  width: "100%",
  "& .paper": {
    height: 250,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listContainer": {
    flex: 1,
    minWidth: 200,
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
}));

interface FailureModeMappingPicklistProps {
  failureModes: IFailureMode[];
  onChange: (selectedFailureModes: IFailureMode[]) => void;
}

function not(a: IFailureMode[], b: IFailureMode[]) {
  return a.filter((value) => !b.some((c) => c === value));
}

const IFailureModeListItem = ({
  store,
  failureMode,
  className,
  onClick,
}: {
  store: RootState;
  failureMode: IFailureMode;
  className: string;
  onClick: (_: IFailureMode) => void;
}) => {
  const componentName = selectorGetComponentByFunctionalFailureId(store, failureMode.functionalFailureId)?.name;
  return (
    <ListItemButton
      key={failureMode.failureModeId}
      role="listitem"
      className={className}
      onClick={() => onClick(failureMode)}
    >
      <ListItemText primary={`[${componentName}] ${failureMode.name}`} />
    </ListItemButton>
  );
};

export default function FailureModeMappingPicklist({ failureModes, onChange }: FailureModeMappingPicklistProps) {
  const store = useSelector((store: RootState) => store);

  const [selected, setSelected] = useState<IFailureMode[]>([]);
  const available = not(failureModes, selected);

  // Allow the parent to receive updates
  useEffect(() => void onChange(selected), [onChange, selected]);

  const selectItem = (clickedItem: IFailureMode) => setSelected([...selected, clickedItem]);
  const deselectItem = (clickedItem: IFailureMode) =>
    setSelected(selected.filter((x) => x.failureModeId !== clickedItem.failureModeId));

  const customList = (items: IFailureMode[], onClick: (_: IFailureMode) => void) => {
    return (
      <Paper className="paper">
        <List dense component="div" role="list">
          {items.map((failureMode) => (
            <IFailureModeListItem
              key={failureMode.failureModeId}
              className="listItem"
              store={store}
              failureMode={failureMode}
              onClick={onClick}
            />
          ))}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <GridWrapper container spacing={2} alignItems="self-start">
      <Grid item className="listContainer">
        <Typography variant="overline">Available</Typography>
        {customList(available, selectItem)}
      </Grid>
      <Grid item className="listContainer">
        <Typography variant="overline">Selected</Typography>
        {customList(selected, deselectItem)}
      </Grid>
    </GridWrapper>
  );
}
