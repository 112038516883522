import React, { useEffect, useState, lazy } from "react";
import { Grid } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux";

import { selectorGetSolverJobById } from "../../../redux/solverJob/selectors";
import { selectorGetSolverById } from "../../../redux/solver/selectors";
import { fetchModelByIdIfNeeded } from "../../../redux/model/actions";

import SolverUIResultSetChartWidget from "../../solverUIResultSetChart/SolverUIResultSetChartWidget";

import SolverJobCollapseCard from "../SolverJobCollapseCard";
import JobDetailPageWrapper from "../../generic/JobDetailPageWrapper";
import { fetchSolverByIdIfNeeded } from "../../../redux/solver/actions";
import SuspenseWrapper from "../../generic/SuspenseWrapper";
import { fetchSummaryOutputTypesIfNeeded } from "../../../redux/summaryOutputType/actions";

const LazyPrerequisiteJobsEnquiryWidget = lazy(() => import("../../generic/widgets/PrerequisiteJobsEnquiryWidget"));
const LazySolverJobTasksEnquiryContainer = lazy(() => import("../../solverJobTask/SolverJobTasksEnquiryContainer"));
const LazySolverJobSummaryReportsEnquiryContainer = lazy(
  () => import("../../solverJobSummaryReports/SolverJobSummaryReportsEnquiryContainer")
);

interface IPageJobDetailsProps {
  routeProps: RouteComponentProps<any>;
  jobId: string;
  loading: boolean;
}

const PageJobDetails = ({ jobId, loading }: IPageJobDetailsProps) => {
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const solverJob = useSelector((store: RootState) => selectorGetSolverJobById(store, jobId));
  const solver = useSelector((store: RootState) => selectorGetSolverById(store, solverJob ? solverJob.solverId : ""));
  const dispatch = useDispatch();
  const [fetchingModel, setFetchingModel] = useState(false);
  const modelId = solverJob?.modelId || "";

  useEffect(() => {
    (async () => {
      await dispatch(fetchModelByIdIfNeeded(modelId));
      setFetchingModel(false);
    })();
  }, [modelId, setFetchingModel, dispatch]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchSummaryOutputTypesIfNeeded());
    })();
  }, [dispatch]);

  useEffect(() => {
    if (solverJob?.solverId) {
      (async () => {
        await dispatch(fetchSolverByIdIfNeeded(solverJob?.solverId));
      })();
    }
  }, [solverJob?.solverId, dispatch]);

  return (
    <JobDetailPageWrapper
      jobLabel="Solver Jobs"
      jobDetailsLabel="Solver Job Details"
      loading={loading || fetchingModel}
    >
      <SolverJobCollapseCard
        solverJob={solverJob}
        solver={solver}
        showCollapse={showCollapse}
        setShowCollapse={setShowCollapse}
      >
        <Grid container rowSpacing={4} columnSpacing={2} style={{ padding: 16 }}>
          {solverJob && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SuspenseWrapper>
                <LazyPrerequisiteJobsEnquiryWidget prerequisites={solverJob.prerequisites} />
              </SuspenseWrapper>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <SuspenseWrapper>
              <LazySolverJobTasksEnquiryContainer solverJobId={jobId} canEdit={true} />
            </SuspenseWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <SuspenseWrapper>
              <LazySolverJobSummaryReportsEnquiryContainer solverJobId={jobId} canEdit={true} />
            </SuspenseWrapper>
          </Grid>
        </Grid>
      </SolverJobCollapseCard>

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={12} id="solver-job-tasks-container">
          <SolverUIResultSetChartWidget solverJobId={jobId} />
        </Grid>
      </Grid>
    </JobDetailPageWrapper>
  );
};

export default PageJobDetails;
